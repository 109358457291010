.ReportMainTopContainer {
	background-color: #f7f7f7;
}

.ReportMainDateContainer {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	justify-self: end;
	align-items: center;
	justify-content: center;
}

.ReportMainDatePrev {
	color: grey;
	font-size: 20px;
	padding: 15px;
	cursor: pointer;
	text-align: right;
	user-select: none;
}

.ReportMainDateString {
	color: black;
	font-size: 16px;
	padding: 5px;
	cursor: pointer;
	text-align: center;
	width: 80px;
	user-select: none;
}

.ReportMainDateNext {
	display: table-cell;
	color: grey;
	font-size: 20px;
	padding: 15px;
	cursor: pointer;
	text-align: left;
	user-select: none;
}

.ReportMainContainer {
	padding-left: 20px;
	padding-right: 20px;
}

.ReportMainFullName {
	padding: 5px;
	font-size: 16px;
	font-weight: bold;
	text-align: left;
}

.ReportMainTaskLink {
	cursor: pointer;
	user-select: none;
}

.ReportMainTaskNameHeader {
	padding: 5px;
	font-size: 16px;
	font-weight: bold;
	width: 30vw;
	text-align: left;
}

.ReportMainTaskNameItem {
	padding: 5px;
	font-size: 16px;
	width: 30vw;
	text-align: left;
}

.ReportMainStatusHeader {
	padding: 5px;
	font-size: 16px;
	font-weight: bold;
	width: 10vw;
	text-align: center;
}

.ReportMainStatusItem {
	padding: 5px;
	font-size: 16px;
	width: 10vw;
	text-align: center;
}

.ReportMainPartHeader {
	padding: 5px;
	font-size: 16px;
	font-weight: bold;
	width: 30vw;
	text-align: left;
}

.ReportMainPartItem {
	padding: 5px;
	font-size: 16px;
	width: 30vw;
	text-align: left;
}

.ReportMainDurationHeader {
	padding: 5px;
	font-size: 16px;
	font-weight: bold;
	width: 20vw;
	text-align: left;
}

.ReportMainDurationItem {
	padding: 5px;
	font-size: 16px;
	width: 20vw;
	text-align: left;
}

.ReportMainToolbarContainer1 {
	display: grid;
	grid-template-columns: 1fr auto;
	height: 23px;
	background-color: #dddddd;
	align-items: center;
	justify-content: center;
	left: 0;
	bottom: 0;
}

.ReportMainToolbarLeft {
	font-size: 12px;
	padding: 2px 2px 2px 2px;
	margin: 0px 0px 0px 10px;
	//min-width: 10px;
	text-align: left;
	margin-left: 10px;
	padding: 0px;
	width: fit-content;
}

.ReportMainToolbarRight {
	margin-right: 10px;
	justify-self: end;
}

.ReportMainToolbarContainer2 {
	display: flex;
	flex-direction: row;
}

.ReportMainToolbarText {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
}

.ReportMainToolbarLink {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
	user-select: none;
}

.ReportMainToolbarSelectedLink {
	font-size: 12px;
	font-weight: bold;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
	user-select: none;
}

.ReportMainTopHeaderContainer {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	margin: 20px;
	width: 50%;
	height: 30px;
	justify-content: space-between;
}

.ReportMainTopHeaderItem1 {
	font-size: 20px;
	min-width: 200px;
	cursor: pointer;
	position: relative;
	width: fit-content;
	justify-self: start;
	text-align: left;
	user-select: none;
}

.ReportOccupancyBoxContainer {
	margin: 20px;
	display: grid;
	grid-gap: 30px;
	grid-template-columns: repeat(auto-fit, 330px);
}

.ReportOccupancyOneBox {
	background-color: white;
	border-width: 1px;
	border-style: solid;
	border-color: #aaaaaa;
	border-radius: 6px;
	font-size: 20px;
	padding: 5px;
	text-align: left;
	border: 1px solid;
	width: 330px;
	position: relative;
}

.ReportOccupancyBoxTitle {
	border-bottom: 1px solid;
	border-color: #aaaaaa;
	font-size: 16px;
	font-weight: bold;
	padding-bottom: 5px;
	cursor: pointer;
	user-select: none;
}

.ReportOccupancyBoxLineContainer {
	display: grid;
	grid-template-columns: 1fr 1fr;
	justify-self: end;
	align-items: center;
	justify-content: center;
}

.ReportOccupancyBoxLeft {
	margin-left: 5px;
	font-size: 16px;
}

.ReportOccupancyBoxRight {
	width: 250px;
	font-size: 16px;
	text-align: right;
}

.ReportOccupancyBoxRightLight {
	margin-top: 0px;
	margin-bottom: 0px;
	padding-top: 0px;
	padding-bottom: 5px;
	color: #777777;
}

.ReportOccupancyYearContainer {
	padding: 10px;
}

.ReportOccupancyYearOneYear {
	display: table-cell;
	font-size: 16px;
	cursor: pointer;
	user-select: none;
	padding: 5px;
}

.ReportOccupancyYearOneYearSelected {
	display: table-cell;
	font-size: 16px;
	font-weight: bold;
	cursor: pointer;
	user-select: none;
	padding: 5px;
}

.ReportOccupancyClosestLastContainer {
	display: grid;
	grid-template-columns: auto auto auto auto auto auto auto;
	align-items: center;
	justify-content: center;
	width: fit-content;
}

.ReportOccupancyClosestLastDay {
	font-size: 16px;
	cursor: pointer;
	user-select: none;
	padding-top: 5px;
}

.ReportOccupancyClosestLastDayDisabled {
	color: grey;
	font-size: 16px;
	user-select: none;
	padding-top: 5px;
}

.ReportOccupancyClosestLastSeparator {
	font-size: 16px;
	user-select: none;
	padding-top: 5px;
	padding-left: 10px;
	padding-right: 10px;
}

.ReportOccupancyGraphFooterContainer {
    margin-left: auto;
    margin-right: auto;
	width: 300px;
	align-items: center;
}

.ReportOccupancyGraphFooterDiv {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr;
	grid-column-gap: 5px;
	grid-row-gap: 0px;
	margin: 0px;
}

.ReportOccupancyGraphFooterTitle1 {
	background: #aaaaaa;
	color: white;
	font-size: 14px;
	font-weight: bold;
	padding: 5px;
}

.ReportOccupancyGraphFooterTitle2 {
	background: #aaaaaa;
	color: white;
	font-size: 14px;
	font-weight: bold;
	padding: 5px;
	text-align: right;
}

.ReportOccupancyGraphFooterItem1 {
	font-size: 14px;
	font-weight: bold;
	padding: 5px;
	text-align: center;
}

.ReportOccupancyGraphFooterItem2 {
	font-size: 14px;
	font-weight: bold;
	padding: 5px;
	text-align: right;
}

.ReportOccupancyGraphCustomTooltip {
	border-style: solid;
	border-width: thin;
	border-color: grey;
	background-color: white;
	padding: 15px 15px 15px 15px;
	user-select: none;
}

.ReportOccupancyGraphPercentageGrey {
	color: grey;
	//font-size: 16px;
}

.ReportOccupancyGraphPercentageRed {
	color: red;
	//font-size: 16px;
}

.ReportOccupancyGraphPercentageGreen {
	color: green;
	//font-size: 16px;
}

.ReportOccupancyProgressCustomTooltip {
	border-style: solid;
	border-width: thin;
	border-color: grey;
	background-color: white;
	padding: 15px 15px 15px 15px;
	user-select: none;
}

.ReportExpensesBillPriceNormal {
}

.ReportExpensesBillPriceRed {
	color: red;
}

.ReportExpensesLine1 {
	background: #e0dafa;
}

.ReportExpensesLine2 {
	background: #eaeaea;
}

.ReportExpensesLineOpen {
	background: white;
}

.ReportExpensesPDFHeader {
	font-size: 16px;
	font-weight: bold;
	width: 5vw;
	padding-left: 30px;
	padding-right: 10px;
}

.ReportExpensesPDFItem {
	font-size: 16px;
	text-align: center;
	padding-top: 10px;
	padding-left: 30px;
	padding-right: 10px;
}

.ReportExpensesUlazniRacunBrojHeader {
	font-size: 16px;
	font-weight: bold;
	width: 5vw;
	padding-left: 10px;
	padding-right: 10px;
}

.ReportExpensesUlazniRacunBrojItem {
	font-size: 16px;
	padding-left: 10px;
	padding-right: 10px;
	cursor: pointer;
	user-select: none;
}

.ReportExpensesDatumHeader {
	font-size: 16px;
	font-weight: bold;
	width: 10vw;
	text-align: center;
	padding-left: 10px;
	padding-right: 10px;
}

.ReportExpensesDatumItem {
	font-size: 16px;
	padding-left: 10px;
	padding-right: 10px;
}

.ReportExpensesKontoHeader {
	font-size: 16px;
	font-weight: bold;
	width: 20vw;
	padding-left: 10px;
	padding-right: 10px;
}

.ReportExpensesKontoItem {
	font-size: 16px;
	padding-left: 10px;
	padding-right: 10px;
}

.ReportExpensesMjTroskaHeader {
	font-size: 16px;
	font-weight: bold;
	width: 20vw;
	padding-left: 10px;
	padding-right: 10px;
}

.ReportExpensesMjTroskaItem {
	font-size: 16px;
	padding-left: 10px;
	padding-right: 10px;
}

.ReportExpensesPoslovniPartnerHeader {
	font-size: 16px;
	font-weight: bold;
	width: 30vw;
	text-align: center;
	padding-left: 10px;
	padding-right: 10px;
}

.ReportExpensesPoslovniPartnerItem {
	font-size: 16px;
	text-align: left;
	padding-left: 10px;
	padding-right: 10px;
}

.ReportExpensesPotrazujeHeader {
	font-size: 16px;
	font-weight: bold;
	width: 10vw;
	text-align: center;
	padding-left: 10px;
	padding-right: 10px;
}

.ReportExpensesPotrazujeItem {
	font-size: 16px;
	text-align: right;
	padding-left: 10px;
	padding-right: 10px;
}

.ReportExpensesInput1 {
	border: 0px;
	font-size: 16px;
	width: 200px;
	border-right: 20px;
}

.ReportExpensesInput2 {
	border: 0px;
	font-size: 16px;
	width: 100px;
}

.ReportExpensesBillClosed {
	font-size: 16px;
	background-color: #f7f7f7;
}

.ReportExpensesBillOpen {
	font-size: 16px;
	background-color: white;
}

.ReportExpensesOpenBillContainer {
	width: 1200px;
    margin-left: auto;
    margin-right: auto;
	margin-bottom: 10px;
}

.ReportExpensesOpenBill {
	font-size: 16px;
	text-align: left;
	padding-left: 10px;
	padding-right: 10px;
	background-color: white;
}

.ReportExpensesMonthlyMonthHeader {
	font-size: 16px;
	font-weight: bold;
	width: 10vw;
	text-align: left;
	padding-left: 10px;
	padding-right: 10px;
}

.ReportExpensesMonthlyMonthItem {
	font-size: 16px;
	text-align: left;
	padding-left: 10px;
	padding-right: 10px;
}

.ReportExpensesMonthlySumHeader {
	font-size: 16px;
	font-weight: bold;
	width: 10vw;
	text-align: right;
	padding-left: 10px;
	padding-right: 10px;
}

.ReportExpensesMonthlySumItem {
	font-size: 16px;
	text-align: right;
	padding-left: 10px;
	padding-right: 10px;
}

.ReportExpensesProductNameHeader {
	font-size: 16px;
	font-weight: bold;
	text-align: left;
	padding-left: 20px;
	padding-right: 20px;
}

.ReportExpensesProductNameItem {
	font-size: 16px;
	text-align: left;
	padding-left: 20px;
	padding-right: 20px;
}

.ReportExpensesProductQuantityHeader {
	font-size: 16px;
	font-weight: bold;
	text-align: right;
	padding-left: 20px;
	padding-right: 20px;
}

.ReportExpensesProductQuantityItem {
	font-size: 16px;
	text-align: right;
	padding-left: 20px;
	padding-right: 20px;
}

.ReportExpensesProductPPUnitHeader {
	font-size: 16px;
	font-weight: bold;
	text-align: right;
	padding-left: 20px;
	padding-right: 20px;
}

.ReportExpensesProductPPUnitItem {
	font-size: 16px;
	text-align: right;
	padding-left: 20px;
	padding-right: 20px;
}

.ReportExpensesProductVATHeader {
	font-size: 16px;
	font-weight: bold;
	text-align: right;
	padding-left: 20px;
	padding-right: 20px;
}

.ReportExpensesProductVATItem {
	font-size: 16px;
	text-align: right;
	padding-left: 20px;
	padding-right: 20px;
}

.ReportExpensesProductPTotalHeader {
	font-size: 16px;
	font-weight: bold;
	text-align: right;
	padding-left: 20px;
	padding-right: 20px;
}

.ReportExpensesProductPTotalItem {
	font-size: 16px;
	text-align: right;
	padding-left: 20px;
	padding-right: 20px;
}

.ReportExpensesProductDetailsHeader {
	font-size: 16px;
	font-weight: bold;
	text-align: right;
	padding-left: 20px;
	padding-right: 20px;
}

.ReportExpensesProductDetailsItem {
	font-size: 16px;
	text-align: right;
	padding-left: 20px;
	padding-right: 20px;
}

.ReportExpensesProductCategoryHeader {
	font-size: 16px;
	font-weight: bold;
	text-align: right;
	padding-left: 20px;
	padding-right: 20px;
}

.ReportExpensesProductCategoryItem {
	font-size: 16px;
	text-align: right;
	padding-left: 20px;
	padding-right: 20px;
}

.ReportExpensesDownloadLink {
	font-size: 16px;
    padding: 5px;
	cursor: pointer;
	user-select: none;
}

.ReportExpensesDownloadContainter {
    margin-left: auto;
    margin-right: auto;
}

.ReportExpensesMonthlyTable {
    margin-left: auto;
    margin-right: auto;
}

